import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '../../../node_modules/@angular/router';
import {  InstitucionalComponent } from './institucional.component';

const routes: Routes = [
  { path: '', component:  InstitucionalComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class  InstitucionalRoutingModule { }
