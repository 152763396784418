import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitucionalRoutingModule } from './institucional-routing.module';
import { InstitucionalComponent } from './institucional.component';

@NgModule({
  imports: [
    CommonModule,
    InstitucionalRoutingModule
  ],
  declarations: [InstitucionalComponent],
  exports: []
})
export class InstitucionalModule { }
