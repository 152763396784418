import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Servico } from '../_model/servico';
declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit {

  private listaServicos = new Array<Servico>();

  constructor() { }

  ngOnInit() {

    jQuery(document).ready(function ($) {
      'use strict';

      /*-------------------------------------
      Page Preloader
      -------------------------------------*/
      $('#preloader').fadeOut('slow', function () {
        $(this).remove();
      });

      /*--------------------------
       scrollUp
      ---------------------------- */

      $('.scrollup').on('click', function () {

        $('html, body').animate({ scrollTop: 0 }, 'slow');

      });

      $('a[data-rel]').each(function () {
        $(this).attr('rel', $(this).data('rel'));
      });

      if ($('#cp-home-banner').length) {
        (<any>$('#cp-home-banner')).bxSlider({
          auto: true,
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp-tweet-slider').length) {
        (<any>$('#cp-tweet-slider')).bxSlider({
          auto: true,
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#history-slider').length) {
        (<any>$('#history-slider')).owlCarousel({
          items: 1,
          autoPlay: true,
          nav: true
        });
      }

      if ($('#blog-slider-2').length) {
        (<any>$('#blog-slider-2')).owlCarousel({
          items: 1,
          autoPlay: true,
          nav: true
        });
      }

      if ($('#cp-welcome-slider').length) {
        (<any>$('#cp-welcome-slider')).bxSlider({
          auto: true,
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp-practice').length) {
        (<any>$('#cp-practice')).owlCarousel({
          loop: true,
          margin: 0,
          responsiveClass: true,
          nav: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2,
              nav: false
            },
            1000: {
              items: 5,
            }
          }
        });
      }

      if ($('.counter').length) {
        (<any>$('.counter')).counterUp({
          delay: 10,
          time: 1000
        });
      }

      if ($('#cp-client').length) {
        (<any>$('#cp-client')).bxSlider({
          auto: true,
          mode: 'fade',
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp-footer-twitter').length) {
        (<any>$('#cp-footer-twitter')).bxSlider({
          auto: true,
          mode: 'fade',
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp_side-menu-btn, #cp-close-btn').length) {
        $('#cp_side-menu-btn, #cp-close-btn').on('click', function () {
          const $navigacia = $('body, #cp_side-menu'),
            val = $navigacia.css('right') === '375px' ? '0px' : '375px';
          $navigacia.animate({ right: val }, 375);
        });
      }

      if ($('#searchtoggl').length) {
        const $searchlink = $('#searchtoggl i');
        const $searchbar = $('.cp-search-outer');
        $('#searchtoggl').on('click', function (e) {
          e.preventDefault();
          // tslint:disable-next-line:triple-equals
          if ($(this).attr('id') == 'searchtoggl') {
            if (!$searchbar.is(':visible')) {
              $searchlink.removeClass('fa-search').addClass('fa-search-minus');
            } else {
              $searchlink.removeClass('fa-search-minus').addClass('fa-search');
            }
            $searchbar.slideToggle(300, function () { });
          }
        });
        $('#searchform').submit(function (e) {
          e.preventDefault();
        });
      }

      if ($('.cp-navigation-section').length) {
        const stickyNavTop = $('.cp-navigation-section').offset().top;
        const stickyNav = function () {
          const scrollTop = $(window).scrollTop();
          if (scrollTop > stickyNavTop) {
            $('.cp-navigation-section').addClass('cp_sticky');
          } else {
            $('.cp-navigation-section').removeClass('cp_sticky');
          }
        };
        stickyNav();
        $(window).scroll(function () {
          stickyNav();
        });
      }

      if ($('.gallery').length) {
        (<any>$('.gallery:first a[rel^="prettyPhoto"]')).prettyPhoto({
          animation_speed: 'normal',
          theme: 'light_square',
          slideshow: 3000,
          autoplay_slideshow: true
        });
        (<any>$('.gallery:gt(0) a[rel^="prettyPhoto"]')).prettyPhoto({
          animation_speed: 'fast',
          slideshow: 10000,
          hideflash: true
        });
      }

      if ($('.cp-gallery-metro-1 .isotope').length) {
        const $container = $('.cp-gallery-metro-1 .isotope');
        (<any>$container).isotope({
          itemSelector: '.item',
          transitionDuration: '0.6s',
          masonry: {
            columnWidth: $container.width() / 12
          },
          layoutMode: 'masonry'
        });
        $(window).resize(function () {
          (<any>$container).isotope({
            masonry: {
              columnWidth: $container.width() / 12
            }
          });
        });
      }

      if ($('.cp-gallery-metro-2 .isotope').length) {
        const $container = $('.cp-gallery-metro-2 .isotope');
        (<any>$container).isotope({
          itemSelector: '.item',
          transitionDuration: '0.6s',
          masonry: {
            columnWidth: $container.width() / 12
          },
          layoutMode: 'masonry'
        }); $(window).resize(function () {
          (<any>$container).isotope({
            masonry: {
              columnWidth: $container.width() / 12
            }
          });
        });
      }

      function attWorkGrid_2() {
        if ($('#gallery-grid-1-masonrywrap').length) {
          const options = {
            itemWidth: 262,
            autoResize: true,
            container: $('#gallery-grid-1-masonrywrap'),
            offset: 30,
            flexibleWidth: 262
          };
          const handler = $('#gallery-grid-1-masonrywrap li');
          (<any>handler).wookmark(options);
        }
      }

      $(window).on('load', function () {
        attWorkGrid_2();
      });

      $('#gallery-grid-1-masonrywrap li div div img').load('load', function () {
        attWorkGrid_2();
      });

      if ($('#testimonial-style-1').length) {
        (<any>$('#testimonial-style-1')).owlCarousel({
          loop: true,
          margin: 30,
          responsiveClass: true,
          nav: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2, nav: false
            },
            1000: { items: 2, }
          }
        });
      }

      if ($('#testimonial-style-2').length) {
        (<any>$('#testimonial-style-2')).owlCarousel({
          loop: true,
          margin: 30,
          responsiveClass: true,
          nav: false,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2,
              nav: false
            },
            1000: {
              items: 2,
            }
          }
        });
      }

      if ($('#testimonial-style-3').length) {
        (<any>$('#testimonial-style-3')).owlCarousel({
          loop: true,
          margin: 30,
          responsiveClass: true,
          nav: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2,
              nav: false
            },
            1000: {
              items: 3,
            }
          }
        });
      }

      if ($('#blog-slider').length) {
        (<any>$('#blog-slider')).owlCarousel({
          items: 1,
          autoPlay: true,
          nav: true
        });
      }

      if ($('#cp-attorneys-slider').length) {
        (<any>$('#cp-attorneys-slider')).owlCarousel({
          autoPlay: true,
          nav: true,
          items: 1,
          itemsDesktop: [1199, 3],
          itemsDesktopSmall: [979, 3]
        });
      }

      if ($('#cp-client-slider').length) {
        (<any>$('#cp-client-slider')).owlCarousel({
          autoPlay: true,
          items: 1,
          itemsDesktop: [1199, 3],
          itemsDesktopSmall: [979, 3]
        });
      }

      if ($('.countdown236').length) {
        let austDay = new Date();
        austDay = new Date(2017, 11 - 1, 11, 15, 35, 0);
        (<any>$('.countdown236')).countdown({ until: austDay, });
        $('#year').text(austDay.getFullYear());
      }

      (<any>$.fn).slideFadeToggle = function (speed, easing, callback) {
        return this.animate({
          opacity: 'toggle',
          height: 'toggle'
        },
          speed,
          easing,
          callback);
      };
      if ($('.accordion_cp').length) {
        (<any>$('.accordion_cp')).accordion({
          defaultOpen: 'section1',
          cookieName: 'nav',
          speed: 'slow',
          animateOpen: function (elem, opts) {
            elem.next().stop(true, true).slideFadeToggle(opts.speed);
          }, animateClose: function (elem, opts) {
            elem.next().stop(true, true).slideFadeToggle(opts.speed);
          }
        });
      }

      if ($('#map_contact_2').length) {
        let map;
        const myLatLng = new google.maps.LatLng(48.85661, 2.35222);
        const myOptions = {
          zoom: 12,
          center: myLatLng,
          zoomControl: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          styles: [{
            saturation: -100,
            lightness: 10
          }],
        };
        map = new google.maps.Map(document.getElementById('map_contact_2'), myOptions);
        const marker = new google.maps.Marker({
          position: map.getCenter(),
          map: map, icon: 'images/map-icon.png'
        });
        marker.getPosition();
      }

      if ($('#content-1').length) {
        (<any>$('#content-1')).mCustomScrollbar({
          horizontalScroll: false
        });
        (<any>$('.content.inner')).mCustomScrollbar({
          scrollButtons: {
            enable: true
          }
        });
      }

      if ($('#map_contact_1').length) {
        let map;
        const myLatLng = new google.maps.LatLng(48.85661, 2.35222);
        const myOptions = {
          zoom: 12,
          center: myLatLng,
          zoomControl: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          styles: [{ saturation: -100, lightness: 10 }],
        };
        map = new google.maps.Map(document.getElementById('map_contact_1'), myOptions);
        const marker = new google.maps.Marker({
          position: map.getCenter(),
          map: map, icon: 'images/map-icon.png'
        });
        marker.getPosition();
      }
    });

    // Assessoria e Consultoria Jurídica
    const objServicoAssessoriaJuridica = new Servico();
    objServicoAssessoriaJuridica.nome = 'Assessoria e Consultoria Jurídica';
    objServicoAssessoriaJuridica.descricao = [
      'Atuação preventiva em empresas.',
      'Assessoria na Administração Pública.',
      'Atividades de consultoria e acompanhamento de negócios e composições extrajudiciais.'];
    objServicoAssessoriaJuridica.favicon = 'icofont-lawyer-alt-2';
    this.listaServicos.push(objServicoAssessoriaJuridica);
    // Consultoria em Negócios
    // const objServicoConsultoriaNegocios = new Servico();
    // objServicoConsultoriaNegocios.nome = 'Consultoria em Negócios';
    // objServicoConsultoriaNegocios.descricao = ['Atividades de consultoria e acompanhamento de negócios e composições extrajudiciais.'];
    // objServicoConsultoriaNegocios.favicon = 'icofont-man-in-glasses';
    // this.listaServicos.push(objServicoConsultoriaNegocios);
    // Direito Administrativo
    const objServicoDireitoAdministrativo = new Servico();
    objServicoDireitoAdministrativo.nome = 'Direito Administrativo';
    objServicoDireitoAdministrativo.descricao = [
      'Licitações.',
      'Defesas fiscais.',
      'Apreensão ilegal de mercadorias.',
      'Obtenção de autorização para emissão de notas fiscais, CND e alvará.',
      'Proteção ao sigilo bancário e fiscal.',
      'Desapropriação.',
      'Licitações e contratos.',
      'Proteção ao sigilo bancário e fiscal.',
      'Desapropriação.',
      'Licitações e contratos.',
    ];
    objServicoDireitoAdministrativo.favicon = 'icofont-handshake-deal';
    this.listaServicos.push(objServicoDireitoAdministrativo);
    // Direito Ambiental
    // const objServicoDireitoAmbiental = new Servico();
    // objServicoDireitoAmbiental.nome = 'Direito Ambiental';
    // objServicoDireitoAmbiental.descricao = [
    //   'Defesas de autuações, assessoramento de projetos, condutas preventivas.',
    //   'Apoio a municípios e entidades públicas.'];
    // objServicoDireitoAmbiental.favicon = 'icofont-tree-alt';
    // this.listaServicos.push(objServicoDireitoAmbiental);
    // Direito Bancário
    const objServicoDireitoBancario = new Servico();
    objServicoDireitoBancario.nome = 'Direito Bancário';
    objServicoDireitoBancario.descricao = [
      'Assessoria em Alienação Fiduciária e Crédito Direto ao Consumidor (CDC).',
      'Revisão do saldo devedor de contas correntes, taxa de juros e tarifas.',
      'Manutenção de bens essenciais na empresa, em caso de busca e apreensão.',
      'Exclusão SERASA/SPC.',
      'Contencioso Judicial.',
      'Procedimentos Administrativos.',
      'Comercial, industrial, rural e habitacional.',
      'Empréstimos pessoais.',
      'Arrendamento Mercantil (Leasing).'];
    objServicoDireitoBancario.favicon = 'icofont-bank';
    this.listaServicos.push(objServicoDireitoBancario);
    // Direito Civil
    const objServicoDireitoCivil = new Servico();
    objServicoDireitoCivil.nome = 'Direito Civil';
    objServicoDireitoCivil.descricao = [
      'Assessoria na confecção e análise de contratos em geral.',
      'Relações de consumo.',
      'Propriedade Intelectual.',
      'Internet.',
      'Contencioso Judicial.',
      'Procedimentos Administrativos.',
      'Ações, defesas, contratos, condutas preventivas.',
      'Indenização em geral: ação e defesa.'];
    objServicoDireitoCivil.favicon = 'icofont-law-alt-3';
    this.listaServicos.push(objServicoDireitoCivil);
    // Direito Consumidor
    const objServicoDireitoConsumidor = new Servico();
    objServicoDireitoConsumidor.nome = 'Direito do Consumidor';
    objServicoDireitoConsumidor.descricao = [
      'Assessoria na confecção e análise de contratos em geral.',
      'Relações de consumo.',
      'Internet.',
      'Contencioso Judicial.',
      'Procedimentos Administrativos.',
      'Ações, defesas, contratos, condutas preventivas.',
      'Indenização em geral: ação e defesa.'];
    objServicoDireitoConsumidor.favicon = 'icofont-shopping-cart';
    this.listaServicos.push(objServicoDireitoConsumidor);
    // Direito Constitucional
    const objServicoDireitoConstitucional = new Servico();
    objServicoDireitoConstitucional.nome = 'Direito Constitucional';
    objServicoDireitoConstitucional.descricao = [
      'Licitações.',
      'Defesas fiscais.',
      'Apreensão ilegal de mercadorias.',
      'Obtenção de autorização para emissão de notas fiscais, CND e alvará.',
      'Proteção ao sigilo bancário e fiscal.',
      'Desapropriação.',
      'Licitações e contratos.',
      'Proteção ao sigilo bancário e fiscal.',
      'Desapropriação.',
      'Licitações e contratos.',
    ];
    objServicoDireitoConstitucional.favicon = 'icofont-law-book';
    this.listaServicos.push(objServicoDireitoConstitucional);
    // Direito de Família e Sucessões
    const objServicoDireitoFamilia = new Servico();
    objServicoDireitoFamilia.nome = 'Direito de Família e Sucessões';
    objServicoDireitoFamilia.descricao = [
      '',
    ];
    objServicoDireitoFamilia.favicon = 'icofont-users-social';
    this.listaServicos.push(objServicoDireitoFamilia);
    // Direito Imobiliário
    const objServicoDireitoImobiliario = new Servico();
    objServicoDireitoImobiliario.nome = 'Direito Imobiliário';
    objServicoDireitoImobiliario.descricao = [
      'Assessoria na confecção e análise de contratos imobiliários.',
      'Assessoria na área de locação.',
      'Assessoria na compra e venda de imóveis.',
      'Perícias em contratos de financiamento.',
      'Indenizações por limitações administrativas, desapropriações e tombamentos.',
      'Procedimentos Administrativos.',
      'Contencioso Judicial.',
      'Condomínio, incorporações, posse, propriedade, contratos.'];
    objServicoDireitoImobiliario.favicon = 'icofont-ui-home';
    this.listaServicos.push(objServicoDireitoImobiliario);
    // Direito Previdenciário
    const objServicoDireitoPrevidenciario = new Servico();
    objServicoDireitoPrevidenciario.nome = 'Direito Previdenciário';
    objServicoDireitoPrevidenciario.descricao = [
      'Gestão de débitos.',
      'Acompanhamento administrativo e judicial de benefícios.',
      'Aposentadorias, auxílio-doença, auxílio-acidente, pensão.',
      'Revisão de benefícios.',
      'Questões envolvendo planos de previdência privada.',
      'Defesas de autuações.',
      'Repetição do indébito.'];
    objServicoDireitoPrevidenciario.favicon = 'icofont-island-alt';
    this.listaServicos.push(objServicoDireitoPrevidenciario);
    // Direito Empresarial e Societário
    const objServicoDireitoSocietario = new Servico();
    objServicoDireitoSocietario.nome = 'Direito Empresarial e Societário';
    objServicoDireitoSocietario.descricao = [
      'Constituição, dissolução, fusão, incorporação, sucessão hereditária, marcas e patentes, direitos autorais, falência, concordata.',
      'Elaboração de instrumentos contratuais visando prevenir questões decorrentes de abertura de sucessão (Herança) e de separação de cônjuges.',
      'Tratamento tributário - reflexos na pessoa fícsica e jurídica.'];
    objServicoDireitoSocietario.favicon = 'icofont-users-alt-5';
    this.listaServicos.push(objServicoDireitoSocietario);
    // Direito Sucessório
    // const objServicoDireitoSucessorio = new Servico();
    // objServicoDireitoSucessorio.nome = 'Direito Sucessório';
    // objServicoDireitoSucessorio.descricao = [
    //   ''];
    // objServicoDireitoSucessorio.favicon = 'icofont-holding-hands';
    // this.listaServicos.push(objServicoDireitoSucessorio);
    // Direito Trabalhista
    const objServicoDireitoTrabalhista = new Servico();
    objServicoDireitoTrabalhista.nome = 'Direito Trabalhista';
    objServicoDireitoTrabalhista.descricao = [
      'Assessoria de forma preventiva.',
      'Contencioso Judicial.',
      'Dissídios individuais e coletivos.',
      'Atuação preventiva.',
      'Reclamatórias trabalhistas: ação e defesa.',
      'Indenização por LER/DORT (Lesões por esforços repetitivos doenças ocupacionais).',
      'Acidentes de trabalho: ação e defesa.'];
    objServicoDireitoTrabalhista.favicon = 'icofont-industries-3';
    this.listaServicos.push(objServicoDireitoTrabalhista);
    // Direito Tributário
    const objServicoDireitoTributario = new Servico();
    objServicoDireitoTributario.nome = 'Direito Tributário';
    objServicoDireitoTributario.descricao = [
      'Recuperação de créditos tributários.',
      'Defesas, repetição do indébito, condutas preventivas.',
      'Defesas em processos criminais de origem tributária.'];
    objServicoDireitoTributario.favicon = 'icofont-coins';
    this.listaServicos.push(objServicoDireitoTributario);
  }

  ngAfterViewInit() {

  }

}
