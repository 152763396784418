import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { IEmail } from '../_model/email';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class ContatoService {
  private emailUrl = '/assets/phpmailer/email.php';

  constructor(private http: Http) {

  }

  enviarEmail(mensagem: IEmail): Observable<IEmail> | any {
    return this.http.post(this.emailUrl, mensagem).map(response => {
      console.log('Email enviado com sucesso', response);
      return response;
    }).catch(error => {
      console.log('Houve uma falha na solicitação de serviço', error);
      return Observable.throw(error);
    });
  }
}
