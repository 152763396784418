import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { Erro404Component } from './components/erro404/erro404.component';
import { InstitucionalModule } from './institucional/institucional.module';
import { HomeModule } from './home/home.module';
import { ContatoModule } from './contato/contato.module';
import { AppRoutingModule } from './app-routing.module';
import { ServicosComponent } from './servicos/servicos.component';
import { EquipeModule } from './equipe/equipe.module';

@NgModule({
  declarations: [
    AppComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    Erro404Component,
    ServicosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ContatoModule,
    HomeModule,
    InstitucionalModule,
    EquipeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
