import { NgModule } from '@angular/core';
import { ContatoComponent } from './contato.component';
import { Routes, RouterModule } from '../../../node_modules/@angular/router';

const routes: Routes = [
  { path: '', component: ContatoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContatoRoutingModule { }
