import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.css']
})
export class SiteFooterComponent implements OnInit {

  anoAtual: string;

  constructor() {
    this.anoAtual = moment().format('YYYY');
   }

  ngOnInit() {
    this.anoAtual = moment().format('YYYY');
  }

}
