import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipeRoutingModule } from './equipe-routing.module';
import { EquipeComponent } from './equipe.component';

@NgModule({
  imports: [
    CommonModule,
    EquipeRoutingModule
  ],
  declarations: [EquipeComponent],
  exports: []
})
export class EquipeModule { }
