import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContatoRoutingModule } from './contato-routing.module';
import { ContatoComponent } from './contato.component';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContatoService } from './contato.service';

@NgModule({
  imports: [
    CommonModule,
    ContatoRoutingModule,
    FormsModule,
    BrowserModule,
    HttpModule,
    ReactiveFormsModule,
  ],
  declarations: [ContatoComponent],
  providers: [ContatoService]
})
export class ContatoModule { }
