import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { ContatoComponent } from './contato/contato.component';
import { Erro404Component } from './components/erro404/erro404.component';
import { ServicosComponent } from './servicos/servicos.component';
import { EquipeComponent } from './equipe/equipe.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full', data: { title: 'Home' } },
    { path: 'home', component: HomeComponent },
    { path: 'equipe', component: EquipeComponent },
    { path: 'institucional', component: InstitucionalComponent },
    { path: 'servicos', component: ServicosComponent },
    { path: 'contato', component: ContatoComponent },
    { path: '**', component: Erro404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }


