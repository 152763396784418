import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IEmail } from '../_model/email';
import { ContatoService } from './contato.service';
declare var google: any;
import swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit, AfterViewInit {

  public mensagem: IEmail = {};

  public formularioContato: FormGroup;

  constructor(private contatoService: ContatoService, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.formularioContato = this.formBuilder.group({
      nome: ['', [Validators.required]],
      telefone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email]],
      assunto: ['', [Validators.required]],
      mensagem: ['', [Validators.required]]
    });

    jQuery(document).ready(function ($) {
      'use strict';

      /*-------------------------------------
Page Preloader
-------------------------------------*/
      $('#preloader').fadeOut('slow', function () {
        $(this).remove();
      });

      /*--------------------------
       scrollUp
      ---------------------------- */

      $('.scrollup').on('click', function () {

        $('html, body').animate({ scrollTop: 0 }, 'slow');

      });

      $('a[data-rel]').each(function () {
        $(this).attr('rel', $(this).data('rel'));
      });

      if ($('#cp-home-banner').length) {
        (<any>$('#cp-home-banner')).bxSlider({
          auto: true,
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp-tweet-slider').length) {
        (<any>$('#cp-tweet-slider')).bxSlider({
          auto: true,
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#history-slider').length) {
        (<any>$('#history-slider')).owlCarousel({
          items: 1,
          autoPlay: true,
          nav: true
        });
      }

      if ($('#blog-slider-2').length) {
        (<any>$('#blog-slider-2')).owlCarousel({
          items: 1,
          autoPlay: true,
          nav: true
        });
      }

      if ($('#cp-welcome-slider').length) {
        (<any>$('#cp-welcome-slider')).bxSlider({
          auto: true,
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp-practice').length) {
        (<any>$('#cp-practice')).owlCarousel({
          loop: true,
          margin: 0,
          responsiveClass: true,
          nav: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2,
              nav: false
            },
            1000: {
              items: 5,
            }
          }
        });
      }

      if ($('.counter').length) {
        (<any>$('.counter')).counterUp({
          delay: 10,
          time: 1000
        });
      }

      if ($('#cp-client').length) {
        (<any>$('#cp-client')).bxSlider({
          auto: true,
          mode: 'fade',
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp-footer-twitter').length) {
        (<any>$('#cp-footer-twitter')).bxSlider({
          auto: true,
          mode: 'fade',
          infiniteLoop: true,
          hideControlOnEnd: true
        });
      }

      if ($('#cp_side-menu-btn, #cp-close-btn').length) {
        $('#cp_side-menu-btn, #cp-close-btn').on('click', function () {
          const $navigacia = $('body, #cp_side-menu'),
            val = $navigacia.css('right') === '375px' ? '0px' : '375px';
          $navigacia.animate({ right: val }, 375);
        });
      }

      if ($('#searchtoggl').length) {
        const $searchlink = $('#searchtoggl i');
        const $searchbar = $('.cp-search-outer');
        $('#searchtoggl').on('click', function (e) {
          e.preventDefault();
          // tslint:disable-next-line:triple-equals
          if ($(this).attr('id') == 'searchtoggl') {
            if (!$searchbar.is(':visible')) {
              $searchlink.removeClass('fa-search').addClass('fa-search-minus');
            } else {
              $searchlink.removeClass('fa-search-minus').addClass('fa-search');
            }
            $searchbar.slideToggle(300, function () { });
          }
        });
        $('#searchform').submit(function (e) {
          e.preventDefault();
        });
      }

      if ($('.cp-navigation-section').length) {
        const stickyNavTop = $('.cp-navigation-section').offset().top;
        const stickyNav = function () {
          const scrollTop = $(window).scrollTop();
          if (scrollTop > stickyNavTop) {
            $('.cp-navigation-section').addClass('cp_sticky');
          } else {
            $('.cp-navigation-section').removeClass('cp_sticky');
          }
        };
        stickyNav();
        $(window).scroll(function () {
          stickyNav();
        });
      }

      if ($('.gallery').length) {
        (<any>$('.gallery:first a[rel^="prettyPhoto"]')).prettyPhoto({
          animation_speed: 'normal',
          theme: 'light_square',
          slideshow: 3000,
          autoplay_slideshow: true
        });
        (<any>$('.gallery:gt(0) a[rel^="prettyPhoto"]')).prettyPhoto({
          animation_speed: 'fast',
          slideshow: 10000,
          hideflash: true
        });
      }

      if ($('.cp-gallery-metro-1 .isotope').length) {
        const $container = $('.cp-gallery-metro-1 .isotope');
        (<any>$container).isotope({
          itemSelector: '.item',
          transitionDuration: '0.6s',
          masonry: {
            columnWidth: $container.width() / 12
          },
          layoutMode: 'masonry'
        });
        $(window).resize(function () {
          (<any>$container).isotope({
            masonry: {
              columnWidth: $container.width() / 12
            }
          });
        });
      }

      if ($('.cp-gallery-metro-2 .isotope').length) {
        const $container = $('.cp-gallery-metro-2 .isotope');
        (<any>$container).isotope({
          itemSelector: '.item',
          transitionDuration: '0.6s',
          masonry: {
            columnWidth: $container.width() / 12
          },
          layoutMode: 'masonry'
        }); $(window).resize(function () {
          (<any>$container).isotope({
            masonry: {
              columnWidth: $container.width() / 12
            }
          });
        });
      }

      function attWorkGrid_2() {
        if ($('#gallery-grid-1-masonrywrap').length) {
          const options = {
            itemWidth: 262,
            autoResize: true,
            container: $('#gallery-grid-1-masonrywrap'),
            offset: 30,
            flexibleWidth: 262
          };
          const handler = $('#gallery-grid-1-masonrywrap li');
          (<any>handler).wookmark(options);
        }
      }

      $(window).on('load', function () {
        attWorkGrid_2();
      });

      $('#gallery-grid-1-masonrywrap li div div img').load('load', function () {
        attWorkGrid_2();
      });

      if ($('#testimonial-style-1').length) {
        (<any>$('#testimonial-style-1')).owlCarousel({
          loop: true,
          margin: 30,
          responsiveClass: true,
          nav: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2, nav: false
            },
            1000: { items: 2, }
          }
        });
      }

      if ($('#testimonial-style-2').length) {
        (<any>$('#testimonial-style-2')).owlCarousel({
          loop: true,
          margin: 30,
          responsiveClass: true,
          nav: false,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2,
              nav: false
            },
            1000: {
              items: 2,
            }
          }
        });
      }

      if ($('#testimonial-style-3').length) {
        (<any>$('#testimonial-style-3')).owlCarousel({
          loop: true,
          margin: 30,
          responsiveClass: true,
          nav: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 2,
              nav: false
            },
            1000: {
              items: 3,
            }
          }
        });
      }

      if ($('#blog-slider').length) {
        (<any>$('#blog-slider')).owlCarousel({
          items: 1,
          autoPlay: true,
          nav: true
        });
      }

      if ($('#cp-attorneys-slider').length) {
        (<any>$('#cp-attorneys-slider')).owlCarousel({
          autoPlay: true,
          nav: true,
          items: 1,
          itemsDesktop: [1199, 3],
          itemsDesktopSmall: [979, 3]
        });
      }

      if ($('#cp-client-slider').length) {
        (<any>$('#cp-client-slider')).owlCarousel({
          autoPlay: true,
          items: 1,
          itemsDesktop: [1199, 3],
          itemsDesktopSmall: [979, 3]
        });
      }

      if ($('.countdown236').length) {
        let austDay = new Date();
        austDay = new Date(2017, 11 - 1, 11, 15, 35, 0);
        (<any>$('.countdown236')).countdown({ until: austDay, });
        $('#year').text(austDay.getFullYear());
      }

      (<any>$.fn).slideFadeToggle = function (speed, easing, callback) {
        return this.animate({
          opacity: 'toggle',
          height: 'toggle'
        },
          speed,
          easing,
          callback);
      };
      if ($('.accordion_cp').length) {
        (<any>$('.accordion_cp')).accordion({
          defaultOpen: 'section1',
          cookieName: 'nav',
          speed: 'slow',
          animateOpen: function (elem, opts) {
            elem.next().stop(true, true).slideFadeToggle(opts.speed);
          }, animateClose: function (elem, opts) {
            elem.next().stop(true, true).slideFadeToggle(opts.speed);
          }
        });
      }

      // if ($('#map_contact_2').length) {
      //   let map;
      //   const myLatLng = new google.maps.LatLng(48.85661, 2.35222);
      //   const myOptions = {
      //     zoom: 12,
      //     center: myLatLng,
      //     zoomControl: true,
      //     mapTypeId: google.maps.MapTypeId.ROADMAP,
      //     mapTypeControl: false,
      //     styles: [{
      //       saturation: -100,
      //       lightness: 10
      //     }],
      //   };
      //   map = new google.maps.Map(document.getElementById('map_contact_2'), myOptions);
      //   const marker = new google.maps.Marker({
      //     position: map.getCenter(),
      //     map: map, icon: 'images/map-icon.png'
      //   });
      //   marker.getPosition();
      // }

      if ($('#content-1').length) {
        (<any>$('#content-1')).mCustomScrollbar({
          horizontalScroll: false
        });
        (<any>$('.content.inner')).mCustomScrollbar({
          scrollButtons: {
            enable: true
          }
        });
      }

      // if ($('#map_contact_1').length) {
      //   let map;
      //   const myLatLng = new google.maps.LatLng(48.85661, 2.35222);
      //   const myOptions = {
      //     zoom: 12,
      //     center: myLatLng,
      //     zoomControl: true,
      //     mapTypeId: google.maps.MapTypeId.ROADMAP,
      //     mapTypeControl: false,
      //     styles: [{ saturation: -100, lightness: 10 }],
      //   };
      //   map = new google.maps.Map(document.getElementById('map_contact_1'), myOptions);
      //   const marker = new google.maps.Marker({
      //     position: map.getCenter(),
      //     map: map, icon: 'images/map-icon.png'
      //   });
      //   marker.getPosition();
      // }
    });

  }

  ngAfterViewInit() {
  }

  get formularioContatoControls() { return this.formularioContato.controls; }

  enviarEmail(mensagem: IEmail) {
    swal({
      title: 'Envio de e-mail!',
      html: 'A operação será encerrada em <strong></strong> segundos.',
    });
    swal.showLoading();

    this.contatoService.enviarEmail(mensagem).subscribe((email: any) => {

      console.log(email);
      // tslint:disable-next-line:triple-equals
      if (email.status == 200) {
        swal.close();
        swal({
          type: 'success',
          title: 'E-mail enviado com sucesso!',
          showConfirmButton: false,
          timer: 3000
        });
      } else {
        swal({
          type: 'error',
          title: 'Oops...',
          text: 'Houve um erro! Entre em contato com o administrador do sistema.',
        });
      }
    });
  }
}
